import React, { useState } from 'react';
import { Checkbox, TextField, MenuItem, Select, InputLabel, FormControl, Tabs, Tab, Box } from '@mui/material';

const About = () => {
    const [selectedItems, setSelectedItems] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [dimensionFilter, setDimensionFilter] = useState('');
    const [deliveryFilter, setDeliveryFilter] = useState('');
    const [selectedTab, setSelectedTab] = useState(0); // State for the selected tab

    const handleCheckboxChange = (productCode) => {
        if (selectedItems.includes(productCode)) {
            setSelectedItems(selectedItems.filter(item => item !== productCode));
        } else {
            setSelectedItems([...selectedItems, productCode]);
        }
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleDimensionFilterChange = (e) => {
        setDimensionFilter(e.target.value);
    };

    const handleDeliveryFilterChange = (e) => {
        setDeliveryFilter(e.target.value);
    };

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const products = [
        {
            name: "Arrangement Balkonbak 27 cm",
            code: "RS27-120301",
            dimensions: "27 cm x 40 cm",
            delivery: "2-3 A1 344 - 6x4x4 - DC +3",
        },
        {
            name: "Arrangement Balkonbak 27 cm",
            code: "RS27-120302",
            dimensions: "27 cm x 40 cm",
            delivery: "2-3 A1 344 - 6x4x4 - DC +3",
        },
        {
            name: "Arrangement Balkonbak 20 cm",
            code: "RS20-120303",
            dimensions: "20 cm x 30 cm",
            delivery: "1-2 A1 344 - 6x4x4 - DC +2",
        },
    ];

    const filteredProducts = products.filter((product) => {
        const matchesSearch = product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            product.code.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesDimension = dimensionFilter ? product.dimensions.includes(dimensionFilter) : true;
        const matchesDelivery = deliveryFilter ? product.delivery.includes(deliveryFilter) : true;

        return matchesSearch && matchesDimension && matchesDelivery;
    });

    return (
        <div>

            <Box>
                <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    aria-label="Tabs example"
                    variant="scrollable"
                    scrollButtons="auto"
                    sx={{
                        borderBottom: 1,
                        borderColor: 'divider',
                        backgroundColor: '#f3f4f6',
                    }}
                    // Customize Tab indicator (active state underline)
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: '#95bb08', // Change the active tab underline color
                        },
                    }}
                >
                    <Tab
                        label="Producten"
                        sx={{
                            textTransform: 'none',
                            color: selectedTab === 0 ? '#95bb08' : '#555', // Active tab text color
                            '&:hover': {
                                color: '#95bb08', // Hover color
                            },
                            '&.Mui-selected': {
                                color: '#95bb08', // Ensure active tab text color is green
                            },
                        }}
                    />
                    <Tab
                        label="Categorie 2"
                        sx={{
                            textTransform: 'none',
                            color: selectedTab === 1 ? '#95bb08' : '#555', // Active tab text color
                            '&:hover': {
                                color: '#95bb08', // Hover color
                            },
                            '&.Mui-selected': {
                                color: '#95bb08', // Ensure active tab text color is green
                            },
                        }}
                    />
                    <Tab
                        label="Categorie 3"
                        sx={{
                            textTransform: 'none',
                            color: selectedTab === 2 ? '#95bb08' : '#555', // Active tab text color
                            '&:hover': {
                                color: '#95bb08', // Hover color
                            },
                            '&.Mui-selected': {
                                color: '#95bb08', // Ensure active tab text color is green
                            },
                        }}
                    />
                </Tabs>
            </Box>

            {/* Filters Section */}
            <div className="flex justify-between mb-4 mt-2 py-2 overflow-x-auto">
                <TextField
                    label="Zoek op naam of code"
                    variant="outlined"
                    size="small"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className="mr-4 w-1/3 bg-white rounded-lg"
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                                borderColor: '#e5e7eb',
                            },
                            "&:hover fieldset": {
                                borderColor: '#95bb08',
                            },
                            "&.Mui-focused fieldset": {
                                borderColor: '#95bb08',
                            },
                        },
                        "& .MuiInputLabel-root": {
                            color: '#555',
                        },
                        "& .Mui-focused .MuiInputLabel-root": {
                            color: '#95bb08',
                        },
                    }}
                />
                <div className="flex space-x-4">
                    <FormControl size="small" className="w-40">
                        <InputLabel>Afmetingen</InputLabel>
                        <Select
                            value={dimensionFilter}
                            onChange={handleDimensionFilterChange}
                            label="Afmetingen"
                        >
                            <MenuItem value="">Alle</MenuItem>
                            <MenuItem value="27 cm">27 cm</MenuItem>
                            <MenuItem value="20 cm">20 cm</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl size="small" className="w-40">
                        <InputLabel>Bezorging</InputLabel>
                        <Select
                            value={deliveryFilter}
                            onChange={handleDeliveryFilterChange}
                            label="Bezorging"
                        >
                            <MenuItem value="">Alle</MenuItem>
                            <MenuItem value="2-3">2-3</MenuItem>
                            <MenuItem value="1-2">1-2</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>

            {/* Conditional Content Based on Selected Tab */}
            {selectedTab === 0 && (
                <div className="p-2 rounded-lg bg-white">
                    {/* Table Section for "Producten" tab */}
                    <table className="min-w-full table-auto border-collapse border border-gray-200">
                        <thead>
                        <tr className="bg-gray-100">
                            <th className="py-2 px-4 text-left border-b">
                                <Checkbox
                                    indeterminate={selectedItems.length > 0 && selectedItems.length < filteredProducts.length}
                                    checked={selectedItems.length === filteredProducts.length}
                                    onChange={() => {
                                        if (selectedItems.length === filteredProducts.length) {
                                            setSelectedItems([]); // Uncheck all if already checked
                                        } else {
                                            setSelectedItems(filteredProducts.map(product => product.code)); // Check all
                                        }
                                    }}
                                />
                            </th>
                            <th className="py-2 px-4 text-left border-b">Artikel</th>
                            <th className="py-2 px-4 text-left border-b">Afmetingen</th>
                            <th className="py-2 px-4 text-left border-b">Bezorging</th>
                        </tr>
                        </thead>
                        <tbody>
                        {filteredProducts.map((product, index) => (
                            <tr
                                key={index}
                                className="border-b hover:bg-gray-50 cursor-pointer"
                                onClick={() => handleCheckboxChange(product.code)}
                            >
                                <td className="py-2 px-4">
                                    <Checkbox
                                        checked={selectedItems.includes(product.code)}
                                        onChange={() => handleCheckboxChange(product.code)}
                                        inputProps={{ 'aria-label': 'checkbox for product selection' }}
                                    />
                                </td>
                                <td className="py-2 px-4 flex items-center">
                                    <img
                                        src="https://app.rspotplants.nl/images/icon.png"
                                        alt="product"
                                        className="mr-4 size-12 rounded-full object-contain"
                                    />
                                    <div>
                                        <p className="text-gray-700 font-semibold">{product.name}</p>
                                        <p className="text-gray-500">{product.code}</p>
                                    </div>
                                </td>
                                <td className="py-2 px-4 text-gray-700">{product.dimensions}</td>
                                <td className="py-2 px-4 text-gray-700">{product.delivery}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            )}

            {selectedTab === 1 && (
                <div>
                    {/* Placeholder for Category 2 */}
                    <p className="text-gray-700">Content for Category 2</p>
                </div>
            )}

            {selectedTab === 2 && (
                <div>
                    {/* Placeholder for Category 3 */}
                    <p className="text-gray-700">Content for Category 3</p>
                </div>
            )}
        </div>
    );
};

export default About;