import React, { useState } from 'react';
import useUsers from '../hooks/useUsers';
import { Backdrop, CircularProgress } from '@mui/material';

const Users = () => {
    const { users, loading, error } = useUsers();

    if (loading) {
        return (
            <Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }
    if (error) {
        return <p>Error: {error}</p>;
    }

    return (
        <div className="p-2 rounded-lg bg-white">
            <table className="min-w-full table-auto border-collapse border border-gray-200">
                <thead>
                <tr className="bg-gray-100">
                    <th className="py-2 px-4 text-left border-b">Name</th>
                    <th className="py-2 px-4 text-left border-b">Email</th>
                </tr>
                </thead>
                <tbody>
                {users.map((user) => (
                    <tr className="border-b hover:bg-gray-50 cursor-pointer">
                        <td className="py-2 px-4 text-gray-700">{user.name}</td>
                        <td className="py-2 px-4 text-gray-700">{user.email}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default Users;