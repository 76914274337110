import { useState, useEffect } from 'react';
import config from '../config';

const useOrganizations = () => {
    const [organizations, setOrganizations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchOrganizations = async () => {
            try {
                const accessToken = localStorage.getItem('accessToken');
                if (!accessToken) {
                    throw new Error('Organization is not authenticated');
                }

                const url = new URL(`${config.baseUrl}/organizations`);
                url.searchParams.append('page', '1');
                url.searchParams.append('take', '10000');

                const response = await fetch(url, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`,
                    },
                });

                if (!response.ok) {
                    const errorText = await response.text();
                    throw new Error(`Failed to fetch organizations: ${response.statusText}. Response: ${errorText}`);
                }

                const data = await response.json();

                if (data && data.data && Array.isArray(data.data)) {
                    setOrganizations(data.data);
                } else {
                    throw new Error('Invalid response structure');
                }
            } catch (err) {
                console.error(err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchOrganizations();
    }, []);

    return { organizations, loading, error };
};

export default useOrganizations;