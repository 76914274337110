import { useState, useEffect } from 'react';
import config from '../config';

const useUsers = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const accessToken = localStorage.getItem('accessToken');
                if (!accessToken) {
                    throw new Error('User is not authenticated');
                }

                const response = await fetch(`${config.baseUrl}/users`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`,
                    },
                });

                if (!response.ok) {
                    const errorText = await response.text();
                    throw new Error(`Failed to fetch users: ${response.statusText}. Response: ${errorText}`);
                }

                const data = await response.json();

                if (data && data.data && Array.isArray(data.data)) {
                    setUsers(data.data);
                } else {
                    throw new Error('Invalid response structure');
                }
            } catch (err) {
                console.error(err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, []);

    return { users, loading, error };
};

export default useUsers;