import React from 'react';
import useTradeItems from '../hooks/useTradeItems';
import { Backdrop, CircularProgress, Button } from '@mui/material';

const TradeItems = () => {
    const { tradeItems, loading, error, page, setPage, totalPages } = useTradeItems();

    const handlePreviousPage = () => {
        if (page > 1) setPage(page - 1);
    };

    const handleNextPage = () => {
        if (page < totalPages) setPage(page + 1);
    };

    if (loading) {
        return (
            <Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }

    if (error) {
        return <p>Error: {error}</p>;
    }

    return (
        <div className="p-2 rounded-lg bg-white">
            <table className="min-w-full table-auto border-collapse border border-gray-200">
                <thead>
                <tr className="bg-gray-100">
                    <th className="py-2 px-4 text-left border-b">ID</th>
                    <th className="py-2 px-4 text-left border-b">Article Gtin</th>
                    <th className="py-2 px-4 text-left border-b">VBN</th>
                    <th className="py-2 px-4 text-left border-b">Ref</th>
                    <th className="py-2 px-4 text-left border-b">Artikel code</th>
                </tr>
                </thead>
                <tbody>
                {tradeItems.map((tradeItem) => (
                    <tr key={tradeItem.id} className="border-b hover:bg-gray-50 cursor-pointer">
                        <td className="py-2 px-4 text-gray-700">{tradeItem.id}</td>
                        <td className="py-2 px-4 text-gray-700">{tradeItem.article_gtin}</td>
                        <td className="py-2 px-4 text-gray-700">{tradeItem.vbn_product_code}</td>
                        <td className="py-2 px-4 text-gray-700">{tradeItem.trade_item_reference}</td>
                        <td className="py-2 px-4 text-gray-700">{tradeItem.supplier_article_code}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            <div className="flex justify-between items-center mt-4">
                <Button
                    variant="contained"
                    color="primary"
                    disabled={page === 1}
                    onClick={handlePreviousPage}
                >
                    Vorige
                </Button>
                <span>Pagina {page} van {totalPages}</span>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={page === totalPages}
                    onClick={handleNextPage}
                >
                    Volgende
                </Button>
            </div>
        </div>
    );
};

export default TradeItems;