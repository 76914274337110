import { useState } from 'react';
import config from '../config';

const useCreateOrganization = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const createOrganization = async (formData, accessToken) => {
        setLoading(true);
        setError(null);

        try {
            const response = await fetch(`${config.baseUrl}/organizations`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Failed to create organization: ${response.statusText}. Response: ${errorText}`);
            }

            const data = await response.json();
            return data;
        } catch (err) {
            console.error(err);
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return { createOrganization, loading, error };
};

export default useCreateOrganization;