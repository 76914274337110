import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import useAddressLookup from '../hooks/useAddressLookup';
import CustomInput from '../components/CustomInput';

const AddressForm = () => {
    const { address, error, loading, fetchAddress } = useAddressLookup();
    const [formData, setFormData] = useState({
        postcode: '',
        houseNumber: '',
        houseNumberAddition: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = () => {
        const { postcode, houseNumber, houseNumberAddition } = formData;
        if (!postcode || !houseNumber) {
            alert('Postcode en huisnummer zijn verplicht.');
            return;
        }
        fetchAddress(postcode, houseNumber, houseNumberAddition);
    };

    return (
        <div className="p-4 max-w-md mx-auto bg-white shadow-lg rounded-md">
            <h1 className="text-xl font-bold mb-4">Adres Lookup</h1>

            <div className="mb-4">
                <CustomInput
                    label="Postcode *"
                    onChange={handleInputChange}
                    value={formData.postcode}
                    name="postcode"
                />
            </div>
            <div className="mb-4">
                <CustomInput
                    label="Huisnummer *"
                    onChange={handleInputChange}
                    value={formData.houseNumber}
                    name="houseNumber"
                />
            </div>
            <div className="mb-4">
                <CustomInput
                    label="Toevoeging"
                    onChange={handleInputChange}
                    value={formData.houseNumberAddition}
                    name="houseNumberAddition"
                />
            </div>

            <Button
                variant="contained"
                fullWidth
                onClick={handleSubmit}
                disabled={loading}
                sx={{
                    backgroundColor: '#1976d2',
                    '&:hover': { backgroundColor: '#115293' },
                }}
            >
                {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Zoek adres op'}
            </Button>

            {error && <p className="text-red-500 mt-4">{error}</p>}

            {address.street && (
                <div className="mt-4">
                    <p className="text-gray-700">
                        <strong>Straat:</strong> {address.street}
                    </p>
                    <p className="text-gray-700">
                        <strong>Plaats:</strong> {address.place}
                    </p>
                </div>
            )}
        </div>
    );
};

export default AddressForm;