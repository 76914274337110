import { useState } from 'react';
import { fetchAddressFromApi } from '../api';

const useAddressLookup = () => {
    const [address, setAddress] = useState({ street: '', place: '' });
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const fetchAddress = async (postcode, houseNumber, houseNumberAddition) => {
        setLoading(true);
        setError('');

        try {
            const fetchedAddress = await fetchAddressFromApi(postcode, houseNumber, houseNumberAddition);
            setAddress(fetchedAddress);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return { address, error, loading, fetchAddress };
};

export default useAddressLookup;