import React, { useState } from 'react';
import useOrganizations from '../hooks/useOrganizations';
import useCreateOrganization from '../hooks/useCreateOrganization';
import { Backdrop, CircularProgress, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Snackbar, Alert } from '@mui/material';
import CustomInput from "../components/CustomInput";

const Organizations = () => {
    const { organizations, loading, error } = useOrganizations();
    const { createOrganization, loading: creating, error: createError } = useCreateOrganization();
    const [openDialog, setOpenDialog] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        company_gln: '',
        organization_type: 1,
    });

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // 'success' or 'error'

    const accessToken = localStorage.getItem('accessToken');

    const handleDialogOpen = () => setOpenDialog(true);
    const handleDialogClose = () => {
        setOpenDialog(false);
        setFormData({ name: '', company_gln: '', organization_type: 1 });
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        if (!accessToken) {
            console.error('Access token ontbreekt');
            return;
        }

        try {
            const response = await createOrganization(formData, accessToken);
            if (response && response.data) {
                console.log('Organisatie succesvol aangemaakt');
                setSnackbarMessage('Organisatie succesvol aangemaakt!');
                setSnackbarSeverity('success');
                setOpenSnackbar(true);
                handleDialogClose();
            } else {
                console.error('Er is een fout opgetreden bij het aanmaken van de organisatie');
                setSnackbarMessage('Er is een fout opgetreden.');
                setSnackbarSeverity('error');
                setOpenSnackbar(true);
            }
        } catch (err) {
            console.error('Er is een fout opgetreden:', err.message);
            setSnackbarMessage('Er is een fout opgetreden.');
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
        }
    };

    if (loading) {
        return (
            <Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }

    if (error) {
        return <p>Error: {error}</p>;
    }

    return (
        <div className="p-2 rounded-lg bg-white">
            <div className="flex justify-between mb-4">
                <h1 className="text-lg font-semibold">Organizations</h1>
                <Button variant="contained" color="primary" onClick={handleDialogOpen}>
                    Organisatie aanmaken
                </Button>
            </div>
            <table className="min-w-full table-auto border-collapse border border-gray-200">
                <thead>
                <tr className="bg-gray-100">
                    <th className="py-2 px-4 text-left border-b">Name</th>
                </tr>
                </thead>
                <tbody>
                {organizations.map((organization) => (
                    <tr key={organization.id} className="border-b hover:bg-gray-50 cursor-pointer">
                        <td className="py-2 px-4 text-gray-700">{organization.name}</td>
                    </tr>
                ))}
                </tbody>
            </table>

            <Dialog open={openDialog} onClose={handleDialogClose}>
                <DialogTitle>Organisatie aanmaken</DialogTitle>
                <form onSubmit={handleFormSubmit}>
                    <DialogContent className="flex justify-start items-start flex-col gap-y-2 w-96">
                        <CustomInput
                            label="Naam"
                            name="name"
                            onChange={handleInputChange}
                            required
                            value={formData.name}
                        />
                        <CustomInput
                            label="Bedrijfs GLN"
                            name="company_gln"
                            onChange={handleInputChange}
                            required
                            fullWidth
                            value={formData.company_gln}
                        />
                        <CustomInput
                            label="Organisatie Type"
                            name="organization_type"
                            onChange={handleInputChange}
                            required
                            fullWidth
                            value={formData.organization_type}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogClose} color="secondary">
                            Annuleren
                        </Button>
                        <Button type="submit" variant="contained" color="primary" disabled={creating}>
                            {creating ? 'Aanmaken...' : 'Aanmaken'}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

            {/* Snackbar voor succes of foutmelding */}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={() => setOpenSnackbar(false)}
            >
                <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default Organizations;