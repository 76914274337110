import { useState } from 'react';
import config from '../config';

const useAuth = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const login = async (email, password) => {
        setLoading(true);
        setError(null);

        try {
            const response = await fetch(`${config.baseUrl}/login`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': '',
                },
                body: JSON.stringify({ email, password })
            });

            const data = await response.json();

            if (data.status) {
                localStorage.setItem('user', JSON.stringify(data.data.user));
                localStorage.setItem('accessToken', data.data.accessToken);
                localStorage.setItem('refreshToken', data.data.refreshToken);
                return data.data;
            } else {
                setError(data.message || 'Login mislukt');
                return null;
            }
        } catch (err) {
            setError(err.message);
            return null;
        } finally {
            setLoading(false);
        }
    };

    const logout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
    };

    return { login, logout, loading, error };
};

export default useAuth;