import React from 'react';

const Services = () => {
    return (
        <div>
            <h2 className="text-3xl font-semibold mb-4">Services</h2>
            <p>Explore the services we offer in this section.</p>
        </div>
    );
};

export default Services;