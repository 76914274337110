import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import CustomInput from "../components/CustomInput";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";

const Login = ({setIsAuthenticated}) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { login, loading, error } = useAuth();
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'email') {
            setEmail(value);
        } else if (name === 'password') {
            setPassword(value);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const result = await login(email, password);

        if (result) {
            setIsAuthenticated(true);
            navigate('/');
        }
    };

    return (
        <div className="flex justify-center items-center w-full h-screen">
            <div className="flex justify-between items-center w-full">
                <div className="relative flex justify-center w-full lg:w-3/5 h-screen p-4">
                    <div class="flex justify-center items-center flex-col w-full">
                        <div class="flex justify-center items-center flex-col lg:w-2/4 w-3/4">
                            <div class="flex justify-center w-full mb-6">
                                <img src="https://app.rspotplants.nl/images/icon.png" alt="Logo"
                                     className="w-10 rounded"/>
                            </div>
                            <h3 class="text-4xl mb-2 font-lato">Welkom terug!</h3>
                            <p class="font-light text-slate-600 text-xs mb-8">
                                Heb je nog geen account?
                                <a href="mailto:info@innovaware.nl" className="text-blue-600">Vraag een account
                                    aan!</a>
                            </p>
                            {error && <p className="text-red-500 text-center mb-4">{error}</p>}
                            <form onSubmit={handleSubmit} className="w-full">
                                <div className="mb-4">
                                    <CustomInput
                                        label="Email"
                                        name="email"
                                        onChange={handleChange}
                                        placeholder="Enter your email"
                                        required
                                        type="email"
                                        value={email}
                                    />
                                </div>
                                <div className="mb-4">
                                    <CustomInput
                                        label="Password"
                                        name="password"
                                        onChange={handleChange}
                                        placeholder="Enter your password"
                                        required
                                        type="password"
                                        value={password}
                                    />
                                </div>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    type="submit"
                                    disabled={loading}
                                    sx={{
                                        backgroundColor: '#1976d2',
                                        '&:hover': {backgroundColor: '#115293'},
                                    }}
                                >
                                    {loading ? (
                                        <CircularProgress size={24} sx={{color: 'white'}}/>
                                    ) : (
                                        'Login'
                                    )}
                                </Button>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="w-full lg:w-2/5 flex justify-center items-center flex-col lg:block hidden">
                    <div className="relative flex justify-center w-full lg:w-full h-screen p-4">
                        <div className="absolute inset-0">
                            <img src="https://app.rspotplants.nl/images/authentication/banner.jpg"
                                 alt="Your Image"
                                 className="w-full h-full bg-center bg-no-repeat bg-cover object-cover"/>
                        </div>
                        <div
                            className="absolute inset-0 bg-gradient-to-t from-black to-secondary-0 rounded-tr-[100px]"></div>
                        <div className="absolute right-4 bottom-4 bg-white py-4 px-6 z-10 rounded-full">
                            <img src="https://app.rspotplants.nl/images/icon.png" alt="Logo" className="w-12"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;