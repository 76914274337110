import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Topbar from './components/Topbar';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import AddressForm from './pages/AddressForm';
import Users from './pages/Users';
import Login from './pages/Login';
import TradeItems from "./pages/TradeItems";
import Organizations from "./pages/Organizations";

const ProtectedRoute = ({ children, isAuthenticated }) => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        if (token) {
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    }, []);

    if (isLoading) {
        return null;
    }

    return isAuthenticated ? children : <Navigate to="/login" />;
};

const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const accessToken = localStorage.getItem('accessToken');
        setIsAuthenticated(!!accessToken);
    }, []);

    return (
        <Router>
            <div className="flex h-screen">
                {isAuthenticated && <Sidebar />}
                <div className="flex flex-col flex-1">
                    {isAuthenticated && <Topbar />}
                    <div className="flex-1 bg-gray-100 px-6">
                        <Routes>
                            <Route
                                path="/login"
                                element={
                                    isAuthenticated
                                        ? <Navigate to="/" />
                                        : <Login setIsAuthenticated={setIsAuthenticated} />
                                }
                            />
                            <Route path="/"
                                   element={
                                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                                        <Home />
                                    </ProtectedRoute>
                                }
                            />
                            <Route path="/about"
                                element={
                                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                                        <About />
                                    </ProtectedRoute>
                                }
                            />
                            <Route path="/services"
                                element={
                                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                                        <Services />
                                    </ProtectedRoute>
                                }
                            />
                            <Route path="/address-form"
                                element={
                                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                                        <AddressForm />
                                    </ProtectedRoute>
                                }
                            />
                            <Route path="/users"
                                element={
                                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                                        <Users />
                                    </ProtectedRoute>
                                }
                            />
                            <Route path="/trade-items"
                                element={
                                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                                        <TradeItems />
                                    </ProtectedRoute>
                                }
                            />
                            <Route path="/organizations"
                                element={
                                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                                        <Organizations />
                                    </ProtectedRoute>
                                }
                            />
                        </Routes>
                    </div>
                </div>
            </div>
        </Router>
    );
};

export default App;