import axios from 'axios';
import config from './config';

const api = axios.create({
    baseURL: config.apiBaseUrl,
    headers: {
        Authorization: `Bearer ${config.apiToken}`,
    },
});

export const fetchAddressFromApi = async (postcode, houseNumber, houseNumberAddition) => {
    try {
        const response = await api.post('/lookup-address', {
            postcode,
            house_number: houseNumber,
            house_letter: houseNumberAddition || null,
            country_code: 'nl',
        });

        if (response.data && response.data.data) {
            return {
                street: response.data.data.straatnaam || '',
                place: response.data.data.woonplaatsnaam || '',
            };
        } else {
            throw new Error('Geen gegevens gevonden.');
        }
    } catch (error) {
        console.error('API Error:', error);
        throw new Error('Er is een fout opgetreden bij het ophalen van adresgegevens.');
    }
};