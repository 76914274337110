import { useState, useEffect } from 'react';
import config from '../config';

const useTradeItems = () => {
    const [tradeItems, setTradeItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [take] = useState(10);

    useEffect(() => {
        const fetchTradeItems = async () => {
            try {
                setLoading(true);
                const accessToken = localStorage.getItem('accessToken');
                if (!accessToken) {
                    throw new Error('User is not authenticated');
                }

                const response = await fetch(`${config.baseUrl}/trade-items?page=${page}&take=${take}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`,
                    },
                });

                if (!response.ok) {
                    const errorText = await response.text();
                    throw new Error(`Failed to fetch trade-items: ${response.statusText}. Response: ${errorText}`);
                }

                const data = await response.json();

                if (data && data.data && Array.isArray(data.data)) {
                    setTradeItems(data.data);

                    setTotalItems(prevTotal => prevTotal + data.data.length);
                } else {
                    throw new Error('Invalid response structure');
                }
            } catch (err) {
                console.error(err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchTradeItems();
    }, [page, take]);

    const totalPages = totalItems ? Math.ceil(totalItems / take) : 0;

    return { tradeItems, loading, error, page, setPage, totalPages };
};

export default useTradeItems;