import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Collapse } from '@mui/material';
import { FaHome, FaInfoCircle, FaServicestack, FaMapMarkedAlt, FaBars, FaUser, FaList } from 'react-icons/fa';

const Sidebar = () => {
    const [isServicesOpen, setIsServicesOpen] = useState(() => {
        const savedState = localStorage.getItem('isServicesOpen');
        return savedState === 'true';
    });
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleServicesMenu = () => {
        setIsServicesOpen((prev) => {
            const newState = !prev;
            localStorage.setItem('isServicesOpen', newState);
            return newState;
        });
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <div className="relative">
            <div className="lg:hidden absolute top-4 left-4 z-10">
                <Button
                    variant="text"
                    onClick={toggleSidebar}
                    sx={{
                        color: 'inherit',
                        textTransform: 'none',
                        padding: 1,
                    }}
                >
                    <FaBars className="h-6 w-6 text-gray-500" />
                </Button>
            </div>

            {/* Sidebar */}
            <div
                className={`lg:w-64 w-full h-full bg-white px-6 py-2 transition-all duration-300 ${
                    isSidebarOpen ? 'block' : 'hidden lg:block'
                }`}
            >
                <div className="flex justify-start">
                    <img src="https://app.rspotplants.nl/images/logo.png" className="rounded-md h-10 object-contain my-2" alt="Logo"/>
                </div>
                <ul className="flex gap-y-1 flex-col mt-4">
                    <li>
                        <NavLink to="/"
                                 className={({isActive}) =>
                                     isActive
                                         ? 'block bg-gray-200 hover:bg-gray-300 rounded-md transition duration-200'
                                         : 'block hover:bg-gray-100 rounded-md transition duration-200'
                                 }
                        >
                            <Button
                                variant="text"
                                fullWidth
                                sx={{
                                    justifyContent: 'flex-start',
                                    color: 'inherit',
                                    textTransform: 'none',
                                    padding: 1,
                                }}
                            >
                                <FaHome className="h-5 w-5 mr-3 text-gray-500"/>
                                Dashboard
                            </Button>
                        </NavLink>
                    </li>

                    <li>
                        <NavLink
                            to="/about"
                            className={({isActive}) =>
                                isActive
                                    ? 'block bg-gray-200 hover:bg-gray-300 rounded-md transition duration-200'
                                    : 'block hover:bg-gray-100 rounded-md transition duration-200'
                            }
                        >
                            <Button
                                variant="text"
                                fullWidth
                                sx={{
                                    justifyContent: 'flex-start',
                                    color: 'inherit',
                                    textTransform: 'none',
                                    padding: 1,
                                }}
                            >
                                <FaInfoCircle className="h-5 w-5 mr-3 text-gray-500"/>
                                Over ons
                            </Button>
                        </NavLink>
                    </li>

                    <li>
                        <div
                            className="hover:bg-gray-100 rounded-md transition duration-200 flex items-center justify-between w-full">
                            <Button
                                variant="text"
                                fullWidth
                                onClick={toggleServicesMenu}
                                sx={{
                                    justifyContent: 'space-between',
                                    color: 'inherit',
                                    textTransform: 'none',
                                    padding: 1,
                                }}
                            >
                                <div className="flex items-center">
                                    <FaServicestack className="h-5 w-5 mr-3 text-gray-500"/>
                                    API
                                </div>
                                <div
                                    className={`transition-transform duration-300 ${
                                        isServicesOpen ? 'rotate-90' : ''
                                    }`}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-5 text-gray-500"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M9 5l7 7-7 7"
                                        />
                                    </svg>
                                </div>
                            </Button>
                        </div>
                        <Collapse in={isServicesOpen} timeout="auto" unmountOnExit>
                            <ul className="pl-6 mt-2 space-y-1">
                                <li>
                                    <NavLink
                                        to="/users"
                                        className={({isActive}) =>
                                            isActive
                                                ? 'block bg-gray-200 hover:bg-gray-300 rounded-md transition duration-200'
                                                : 'block hover:bg-gray-100 rounded-md transition duration-200'
                                        }
                                    >
                                        <Button
                                            variant="text"
                                            fullWidth
                                            sx={{
                                                justifyContent: 'flex-start',
                                                color: 'inherit',
                                                textTransform: 'none',
                                                padding: 1,
                                            }}
                                        >
                                            Users
                                        </Button>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to="/trade-items"
                                        className={({isActive}) =>
                                            isActive
                                                ? 'block bg-gray-200 hover:bg-gray-300 rounded-md transition duration-200'
                                                : 'block hover:bg-gray-100 rounded-md transition duration-200'
                                        }
                                    >
                                        <Button
                                            variant="text"
                                            fullWidth
                                            sx={{
                                                justifyContent: 'flex-start',
                                                color: 'inherit',
                                                textTransform: 'none',
                                                padding: 1,
                                            }}
                                        >
                                            Trade items
                                        </Button>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to="/organizations"
                                        className={({isActive}) =>
                                            isActive
                                                ? 'block bg-gray-200 hover:bg-gray-300 rounded-md transition duration-200'
                                                : 'block hover:bg-gray-100 rounded-md transition duration-200'
                                        }
                                    >
                                        <Button
                                            variant="text"
                                            fullWidth
                                            sx={{
                                                justifyContent: 'flex-start',
                                                color: 'inherit',
                                                textTransform: 'none',
                                                padding: 1,
                                            }}
                                        >
                                            Organizations
                                        </Button>
                                    </NavLink>
                                </li>
                            </ul>
                        </Collapse>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Sidebar;