import React, { useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

const Home = () => {
    return (
        <div className="container mx-auto p-4">
            Dashboard
        </div>
    );
};

export default Home;