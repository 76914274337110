import React from 'react';

const CustomInput = ({ label, value, onChange, name, ...props }) => {
    const inputId = `${name}-input`;

    return (
        <div className="w-full">
            {label && (
                <label
                    htmlFor={inputId}
                    className="block text-sm font-medium text-gray-600 mb-1"
                >
                    {label}
                </label>
            )}
            <input
                id={inputId}
                name={name}
                value={value}
                onChange={onChange}
                className="w-full px-4 py-3 text-gray-900 bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 focus-visible:ring-blue-500 focus-visible:border-blue-500"
                {...props}
            />
        </div>
    );
};

export default CustomInput;