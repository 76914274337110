import React, { useState } from 'react';
import {Button, Menu, MenuItem} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const Topbar = () => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className="w-full bg-gray-100 text-white p-4 flex justify-end items-center">
            <div className="flex items-center space-x-4">

                <div className="bg-gray-100 rounded-lg text-gray-900">
                    <Button
                        onClick={handleMenuOpen}
                        variant="text"
                        fullWidth
                        sx={{
                            justifyContent: 'flex-start',
                            color: 'inherit',
                            textTransform: 'none',
                            paddingX: 2,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                        }}
                    >
                        <ArrowDropDownIcon sx={{fontSize: 20}}/>
                        Profiel
                    </Button>
                </div>

                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    PaperProps={{
                        style: {
                            borderRadius: '8px',
                            minWidth: '160px',
                            boxShadow:
                                '0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.06)',
                        },
                    }}
                >
                    <MenuItem onClick={handleMenuClose}>
                        <AccountCircleIcon className="mr-2 text-blue-500"/>
                        My Profile
                    </MenuItem>
                    <MenuItem onClick={handleMenuClose}>
                        <LogoutIcon className="mr-2 text-red-500"/>
                        Logout
                    </MenuItem>
                </Menu>
            </div>
        </div>
    );
};

export default Topbar;